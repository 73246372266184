
function snipMe() {

    $(document).ready(function() {


      $("table.hello2").each(function(i,v){
        if(!$(this).find("tbody").html()){
            $(this).hide()
        }
     })
     
        $('.A4').each(function() {
          snipMeA4.call(this);
        });

           //----newly added-----
           $('.A5').each(function() {
            snipMeA5.call(this);
          });

          // //----newly added-----
          // $('.btnA5').each(function(i) {
          //   $(this).val(i+1);
          //   });
          //   //----newly added-----
            
      //----newly added-----



      $("div.hideRowContainingZeroValue").filter(function(){
        //alert(parseInt($(this).text().trim().substring(3)));
        return +parseInt($(this).text().trim().substring(3)) === 0;
     }).closest('tr').hide();


     

     $("div.hideRowContainingZeroValue1").filter(function(){
      //alert(parseInt($(this).text().trim().substring(33)));
      return +parseInt($(this).text().trim().substring(33)) === 0;
   }).closest('tr').hide();
   

      });


}


function dashboardSniper(){
  $(document).ready(function() {
    getRanomImage.call(this);
    animateHeadline($('.cd-headline'));

});
}